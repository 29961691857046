
import { computed, defineComponent, ref } from 'vue';

import AdvancedCalculatorView from '@/view/AdvancedCalculatorView.vue';
import AppSettings from '@/component/AppSettings.vue';
import AppHeader from '@/component/AppHeader.vue';

import { FuelType } from '@/model/FuelType';

import { useOxyFuelCalculatorProvider } from '@/provider/OxyFuelCalculatorProvider';
import {
	createOxyFuelCalculator,
	getAvailableFuels,
} from '@/model/OxyFuelCalculatorFactory';
import { useI18n } from 'vue-i18n';

const defaultFuel = FuelType.Propane;

export default defineComponent({
	components: {
		AdvancedCalculatorView,
		AppSettings,
		AppHeader,
	},

	setup() {
		const { fuel, fuelName } = useFuel();
		const { availableFuels } = useAvalableFuels();

		const calculator = computed(() => {
			return createOxyFuelCalculator(fuel.value);
		});
		useOxyFuelCalculatorProvider(calculator);

		return { fuel, fuelName, availableFuels };
	},
});

function useFuel() {
	const fuel = ref(defaultFuel);
	const fuelName = computed(() => fuel.value);

	return { fuel, fuelName };
}

function useAvalableFuels() {
	const { t } = useI18n();

	const availableFuels = getAvailableFuels().map((fuel) => {
		return { name: t(fuel), value: fuel };
	});

	return { availableFuels };
}
