
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		oxygenUsage: {
			type: Number,
			required: true,
		},

		fuelUsage: {
			type: Number,
			required: true,
		},

		fuelName: {
			type: String,
			required: true,
		},
	},
	data() {
		return { girdersCount: 1 };
	},
	methods: {
		// @ts-ignore
		formatResult(input) {
			return parseFloat(input.toFixed(1));
		},

		toggleGirdersCount() {
			this.girdersCount = 3 - this.girdersCount;
		},
	},
});
