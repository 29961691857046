
import { defineComponent } from 'vue';

import CustomInput from '@/component/input/CustomInput.vue';
import CustomSelect from '@/component/input/CustomSelect.vue';

export default defineComponent({
	components: { CustomInput, CustomSelect },
	props: {
		jointsNumber: {
			type: Number,
			required: true,
		},
		thickness: {
			type: Number,
			required: true,
		},
		thicknessList: {
			type: Array,
			required: true,
		},
	},
	emits: ['update:jointsNumber', 'update:thickness'],
});
