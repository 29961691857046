import { OxyFuelFactorMap } from '@/model/BaseOxyFuelCalculator';

export const oxyPropaneFactors: OxyFuelFactorMap = {
	6: [0.133, 0.027],
	8: [0.166, 0.034],
	10: [0.202, 0.04],
	12: [0.236, 0.048],
	14: [0.313, 0.055],
	16: [0.332, 0.059],
	20: [0.359, 0.07],
	25: [0.455, 0.082],
	30: [0.507, 0.088],
	40: [0.644, 0.102],
	50: [0.795, 0.12],
	60: [0.9, 0.13],
	70: [1.132, 0.154],
	80: [1.332, 0.17],
	90: [1.51, 0.188],
};
