<template>
	<header class="header">
		<svg
			class="logo"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1920 654"
		>
			<path
				d="M1673.34,393.5,1606,304.83l-67.72,88.67h-32.24l83.83-108.82-79.8-103.19h32.24l63.69,83,63.68-83h31.44l-79.81,103.19L1706,393.5Z"
				class="logo__text"
			/>
			<path
				d="M1919.6,181.49l-106,237.4q-12.9,29.82-29.83,42.32t-40.71,12.5a82.91,82.91,0,0,1-28.61-4.84,62,62,0,0,1-23-14.51l13.3-21.36q16.13,16.11,38.7,16.12,14.51,0,24.78-8.06t19.15-27.41l9.27-20.55L1702,181.49h29.83l79.8,180.17,79.81-180.17Z"
				class="logo__text"
			/>
			<path
				d="M351.06,181.25l-80.21,212h-27L176.94,219.54,110,393.25H83l-79.81-212H30.63L97.14,361.41l68.11-180.16h24.59l67.71,180.16,67.31-180.16Z"
				class="logo__text"
			/>
			<path
				d="M572.74,296.12H395.39q2.43,33,25.4,53.4t58,20.36a91.33,91.33,0,0,0,36.27-7.06,79.15,79.15,0,0,0,28.62-20.76l16.12,18.54a90.76,90.76,0,0,1-35.27,25.8q-21.15,8.87-46.55,8.87-32.64,0-57.84-13.91a100.32,100.32,0,0,1-39.3-38.49q-14.1-24.58-14.1-55.62t13.5-55.62a97.78,97.78,0,0,1,37.08-38.29q23.58-13.69,53-13.71t52.8,13.71a97.21,97.21,0,0,1,36.68,38.09q13.3,24.39,13.3,55.82ZM419,223.77q-20.76,19.54-23.58,51H545.73q-2.82-31.42-23.57-51t-51.8-19.55Q439.73,204.22,419,223.77Z"
				class="logo__text"
			/>
			<path
				d="M794.82,193.14a96.62,96.62,0,0,1,37.88,38.08q13.69,24.6,13.71,56t-13.71,56a97.87,97.87,0,0,1-37.88,38.29q-24.2,13.71-54,13.71Q714.2,395.27,693,384a87.16,87.16,0,0,1-34-32.65v41.92H631.58V94.19H660.2V221.55a88.25,88.25,0,0,1,34-31.24q20.76-10.67,46.56-10.68Q770.63,179.63,794.82,193.14ZM779.1,359.6a72.56,72.56,0,0,0,28.41-29.22q10.28-18.94,10.28-43.13t-10.28-43.13A72.62,72.62,0,0,0,779.1,214.9a80.37,80.37,0,0,0-40.31-10.28q-22.57,0-40.51,10.28a73,73,0,0,0-28.21,29.22q-10.27,19-10.28,43.13t10.28,43.13a72.93,72.93,0,0,0,28.21,29.22q17.94,10.27,40.51,10.28A80.37,80.37,0,0,0,779.1,359.6Z"
				class="logo__text"
			/>
			<path
				d="M1035.5,539.54q-68.13-37.49-106.41-102.78T890.8,290.05q0-81.42,38.29-146.72T1035.5,40.56q68.1-37.48,152-37.49t151.14,37.08q67.31,37.1,106,102.78t38.7,147.12q0,81.42-38.7,147.11t-106,102.78Q1271.27,577,1187.45,577T1035.5,539.54Zm272.86-45.95A218.85,218.85,0,0,0,1393,409.75q30.63-53.6,30.64-119.7T1393,170.34a219,219,0,0,0-84.64-83.84q-54-30.23-120.91-30.23T1066.13,86.5a220.88,220.88,0,0,0-85.45,83.84q-31,53.61-31,119.71t31,119.7a220.74,220.74,0,0,0,85.45,83.84q54.4,30.23,121.32,30.23T1308.36,493.59Z"
				class="logo__oxygen"
			/>
			<path
				d="M1525,628v25.67H1422.1V633.2l52.51-49.59q8.36-8,11.28-13.71a25,25,0,0,0,2.92-11.38q0-8.16-5.55-12.54T1467,541.6a37.45,37.45,0,0,0-16.15,3.4,31,31,0,0,0-12.05,10.21l-22.95-14.78a53.93,53.93,0,0,1,22-18.57q14-6.71,32.09-6.71,15.16,0,26.55,5a41,41,0,0,1,17.69,14,36.2,36.2,0,0,1,6.32,21.3,47.48,47.48,0,0,1-4.66,20.81q-4.68,9.72-18.09,22.17L1466.44,628Z"
				class="logo__oxygen"
			/>
		</svg>
	</header>
</template>

<style lang="scss" scoped>
.header {
	margin-bottom: 2rem;
	text-align: center;

	&__text {
		font-size: 3em;
		font-weight: 300;
	}
}

.logo {
	max-width: 24rem;

	&__text {
		fill: #212529;
	}

	&__oxygen {
		fill: #3b4c6f;
		transition: fill 1s;

		:hover > & {
			fill: #b8422c;
		}
	}
}
</style>
