
import { defineComponent } from 'vue';

import InputGroup from '@/component/InputGroup.vue';
import CustomSelect from '@/component/input/CustomSelect.vue';

export default defineComponent({
	components: { CustomSelect, InputGroup },
	props: {
		availableFuels: {
			type: Array,
			required: true,
		},
		fuel: {
			type: String,
			required: true,
		},
	},
});
