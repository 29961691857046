
import { defineComponent } from 'vue';

import CustomInput from '@/component/input/CustomInput.vue';

export default defineComponent({
	components: { CustomInput },
	props: {
		height: {
			type: Number,
			required: true,
		},
		width: {
			type: Number,
			required: true,
		},
		length: {
			type: Number,
			required: true,
		},
	},
	emits: ['update:height', 'update:width', 'update:length'],
});
