
import { defineComponent } from 'vue';

import CustomCheckbox from '@/component/input/CustomCheckbox.vue';

export default defineComponent({
	components: { CustomCheckbox },
	props: {
		fullPenetrationTop: {
			type: Boolean,
			required: true,
		},

		fullPenetrationBottom: {
			type: Boolean,
			required: true,
		},
	},
	emits: ['update:fullPenetrationTop', 'update:fullPenetrationBottom'],
});
