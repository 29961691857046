import { OxyFuelFactorMap } from '@/model/BaseOxyFuelCalculator';

export const oxyAcetyleneFactors: OxyFuelFactorMap = {
	6: [0.102, 0.018],
	8: [0.13, 0.022],
	10: [0.158, 0.028],
	12: [0.184, 0.033],
	14: [0.25, 0.04],
	16: [0.27, 0.045],
	20: [0.292, 0.05],
	25: [0.37, 0.063],
	30: [0.43, 0.07],
	40: [0.57, 0.086],
	50: [0.71, 0.101],
	60: [0.81, 0.11],
	70: [1.02, 0.13],
	80: [1.2, 0.143],
	90: [1.36, 0.158],
};
