
import { defineComponent } from 'vue';

import FlangeValuesInput from '@/component/FlangeValuesInput.vue';
import GirderValuesInput from '@/component/GirderValuesInput.vue';
import WebPlatesInput from '@/component/WebPlatesInput.vue';
import OxyFuelUsageComp from '@/component/OxyFuelUsage.vue';
import InputGroup from '@/component/InputGroup.vue';

import { useOxyFuelCalculatorContext } from '@/provider/OxyFuelCalculatorProvider';
import { useAdvancedCalculator } from '@/composable/UseAdvancedCalculator';

export default defineComponent({
	props: {
		fuelGasName: {
			type: String,
			required: true,
		},
	},

	components: {
		FlangeValuesInput,
		GirderValuesInput,
		OxyFuelUsageComp,
		WebPlatesInput,
		InputGroup,
	},

	setup() {
		const calculatorRef = useOxyFuelCalculatorContext();
		return useAdvancedCalculator(calculatorRef);
	},
});
